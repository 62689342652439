import { useState } from "react"
import Input from "../ui/Input"
import './quick.scss'
import useClassName from "../hooks/useClassName"
import Modal from "../ui/Modal"
import prices from "../lib/getPrices"

const Main = () => {
    const [pro, setPro] = useState("")
    const [lite, setLite] = useState("")
    return (
        <>
            <Input placeholder="Enter Total Pro Users" type="number" onChange={e => setPro(e.target.value)} />
            <Input placeholder="Enter Total Lite Users" onChange={e => setLite(e.target.value)} />
            <h4 className="h5">
                Total Pro Price: ${prices.getProPrices(pro)} <br />
                Total Lite Price: ${prices.getLitePrices(lite)} <br />
                Total Cost: ${prices.getTotal(lite, pro)}
            </h4>
        </>
    )
}

export default function Quickquote() {
    const tools = useClassName()
    return (
        <>
            <button className='btn-primary' onClick={tools.onActive}>Get Quick Quote</button>
            {tools.className && (
                <Modal title="Get Quick Quote" {...tools}>
                    <Main />
                </Modal>
            )}
        </>
    )
}