import { Link } from "react-router-dom";
import Header from "../components/header/header";
import Form from "../hoc/form/form";
import Input from "../ui/Input";
import emailReg from "../constants/emailReg";
import { useState } from "react";
import axios from "../constants/axios";

export default function Login() {
    const [isTeacher, setTeacher] = useState(true);
    return (
        <>
            <Header />
            <div className="py-page-0 cont flex flex-col items-center">
                <div className="flex justify-center sm:gap-10 gap-6">
                    <button className={`flex-1 h5 ${isTeacher ? 'btn-primary' : ''}`} onClick={() => setTeacher(true)}>
                        Teacher
                    </button>
                    <button className={` flex-1 h5 ${isTeacher ? '' : 'btn-primary'}`} onClick={() => setTeacher(false)}>
                        Student
                    </button>
                </div>
                <Form autoComplete="new-password"
                    className="my-10 sm:p-12 md:shadow-2 md:bg-white w-full sm:w-[initial] md:px-24 grid gap-8 md:gap-12"
                    onSubmit={e => {
                        axios.post(`/api/education/users/login/${isTeacher ? 'teacher' : 'student'}`, e.values)
                            .then(res => {
                                document.cookie = `token=${res.data.token}; domain=.artziii.com;`;
                                const user = res.data.user
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                for (const key in user) document.cookie = `${key}=${user[key]}; domain=.artziii.com;`;
                                const random = Math.random().toString();
                                localStorage.setItem('random', random);
                                document.cookie = `random=${random}; domain=.artziii.com;`;
                                window.location.href = 'https://app.artziii.com/dashboard'
                            })
                            .catch(e.onFailure)

                    }}
                    footer={(loading, className) => (
                        <>
                            <button
                                className={`btn-primary -mt-2 ${className}`}
                                disabled={loading}
                            >Login</button>
                            <p className="text-center md:-mt-8 -mt-4">Need an account? <Link to='/signup' className="text-gradient-0">Sign-up here.</Link></p>
                        </>
                    )}>

                    {isTeacher ? (
                        <>
                            <Input placeholder="Email" name="email" className="sm:min-w-96" reg={emailReg} />
                            <Input placeholder="Password" type="password" minLength={6} required errorText="Password must be minimum 6 characters long!" name="password" />
                        </>
                    ) : (
                        <>
                            <Input placeholder="Teacher Email" name="teacherEmail" className="sm:min-w-96" reg={emailReg} />
                            <Input placeholder="First Name" name="firstName" />
                            <Input placeholder="Last Name" name="lastName" />
                        </>
                    )}
                    <Link to="/forgot-password" className="text-gradient-0 ml-auto -mt-6 md:-mt-10">Forgot your password</Link>
                </Form>
            </div>
        </>
    )
}